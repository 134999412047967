import React from "react";
import { withStyles } from "@material-ui/core";
import { serifTypography } from "../../gatsby-theme-hypercore/theme";
import clsx from "clsx";
import quoteBackground from '../../../static/images/quoteBackground.svg'
import quote from '../../../static/images/quote.svg'
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import scribble from '../../../static/images/scribble.svg'

const styles = (theme) => ({
  quoteSlider: {
    paddingBottom: 40,
    width: '100%',
    [theme.breakpoints.up("md")]: {
      marginLeft: 'auto',
      maxWidth: '55%',
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: 500,
    },
    '&::before': {
      backgroundImage: `url(${scribble})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      content: '""',
      display: 'flex',
      height: 33,
      position: 'absolute',
      top: 50,
      width: 90,
      zIndex: 0,
      [theme.breakpoints.up("lg")]: {
        right: 65,
      },
      [theme.breakpoints.only("md")]: {
        display: 'none',
      },
      [theme.breakpoints.down("sm")]: {
        bottom: -30,
        right: '1.5rem',
        top: 'unset',
      },
    },
    '& .slick-slide > div': {
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(4),
      paddingTop: theme.spacing(1),
      [theme.breakpoints.down("xs")]: {
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(2),
      },
    },
    '& .slick-slider': {
      position: 'unset',
      '& .slick-list': {
        overflow: 'visible',
        '& .slick-track': {
          display: 'flex',
          '& .slick-slide': {
            alignSelf: 'center',
            display: 'flex',
            '& > div': {
              width: '100%',
              '& quotetext span': {
                display: 'flex',
              },  
            },
          },
        },
      },
      '& .slick-dots': {
        bottom: -30,
        left: 0,
        '& li': {
          height: 16,
          margin: 0,
          width: 16,
          '&.slick-active': {
            '& button': {
              '&:before': {
                color: '#D7790F',
                opacity: 1,
              }
            }
          },
          '& button': {
            height: 16,
            width: 16,
            '&:before': {
              color: '#E5EDE0',
              height: 16,
              lineHeight: '16px',
              opacity: 1,
              width: 16,
            },
            '&:focus': {
              '&:before': {
                outline: '1px solid #4D4D4D',
              },
            }
          }
        }
      },
    },
  },
  quote: {
    backgroundImage: `url(${quoteBackground})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: "auto",
    maxWidth: '100%',
    position: 'relative',
    zIndex: 1,
    [theme.breakpoints.up("lg")]: {
      height: 350,
      marginRight: 60,
      paddingBottom: theme.spacing(6),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(8),
      paddingTop: theme.spacing(12),
      width: 550,
    },
    [theme.breakpoints.only("md")]: {
      height: 290,
      marginRight: "auto",
      paddingBottom: theme.spacing(6),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(5),
      paddingTop: theme.spacing(12),
      width: 450,
    },
    [theme.breakpoints.down("sm")]: {
      backgroundImage: 'none',
      height: 'auto',
      marginRight: 0,
      width: 'auto',
    },
  },
  quoteText: {
    ...serifTypography,
    fontSize: '.875rem',
    fontStyle: 'italic',
    lineHeight: 1.5,
    position: "relative",
    zIndex: 0,
    [theme.breakpoints.down("sm")]: {
      //fontSize: 14,
    },
    '& ::before': {
      backgroundImage: `url(${quote})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      content: '""',
      display: 'flex',
      height: 33,
      left: -32,
      position: 'absolute',
      top: -15,
      width: 56,
      zIndex: -1,
      [theme.breakpoints.down("xs")]: {
        height: 20,
        left: -40,
        top: 3,
        width: 30,
      },
    },
    '& ::after': {
      backgroundImage: `url(${quote})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      bottom: -15,
      content: '""',
      display: 'flex',
      height: 33,
      position: 'absolute',
      right: 10,
      transform: 'rotate(180deg)',
      width: 56,
      zIndex: -1,
      [theme.breakpoints.down("xs")]: {
        bottom: -22,
        height: 20,
        right: 80,
        width: 30,
      },
    },
  },
  author: {
    color: '#7B7B73',
    display: 'flex',
    fontFamily: "neue-haas-grotesk-display, sans-serif",
    fontSize: 10,
    fontWeight: 'bold',
    letterSpacing: '.2em',
    marginTop: theme.spacing(3.5),
    textTransform: 'uppercase',
    [theme.breakpoints.down("sm")]: {
      fontSize: 9,
    },
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: 8,
  },
});

function StoryListQuote({ classes, className, quote, author }) {
  const settings = {
    accessibility: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 10000,
    dots: true,
    fade: true,
    focusOnChange: true,
    infinite: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 1000,
  };
  
  return (
    <div className={classes.quoteSlider}>
      <quote className={clsx(classes.quote, className)}>
        <Slider {...settings}>
          <div>
            <quotetext className={classes.quoteText}><span>This should be a moment where we reevaluate the way we respond to harm in our society. And … this country’s efforts to incarcerate our way out of harm and behavior that we don’t like does not work.</span></quotetext>
            <author className={clsx(classes.author, className)}>Grace Harris</author>
          </div>
          <div>
            <quotetext className={classes.quoteText}><span>We are all connected. And particularly when you’re talking about contagious disease -- the virus doesn’t respect the prison walls. And if it’s in the prison today, the staff will have it tomorrow. And the staff’s family and the community will have it the day after that.</span></quotetext>
            <author className={clsx(classes.author, className)}>David Fathi</author>
          </div>
          <div>
            <quotetext className={classes.quoteText}><span>At a personal level, this is the most sort of secondary trauma I’ve had from any, any legal work I’ve ever done and it’s not even a close call.</span></quotetext>
            <author className={clsx(classes.author, className)}>Phil Mayor</author>
          </div>
          <div>
            <quotetext className={classes.quoteText}><span>This was a time when people were paying so much attention to people in prison and people in jail because ... they were sitting ducks, they couldn’t distance, they couldn’t get access to medical care unless the jail decided to give it to them.</span></quotetext>
            <author className={clsx(classes.author, className)}>Alexa Van Brunt</author>
          </div>
        </Slider>
      </quote>
    </div>
  );
}

export default withStyles(styles)(StoryListQuote);
