import React from "react";
import { subtitleTypography, titleTypography } from "../../gatsby-theme-hypercore/theme";
import clsx from "clsx";
import {
  withStyles,
  theme,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import slugify from "slugify";
import moment from "moment";
import arrow from '../../../static/images/arrow.svg'

const styles = (theme) => ({
  listItem: {
    alignItems: 'start',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: theme.spacing(3),
    '&:not(:last-child)': {
      '&::after': {
        color: '#92926C',
        content: '"............................"',
        display: 'block',
        fontSize: 20,
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        position: 'relative',
      }  
    },
  },
  itemDetails: {
    color: '#555526',
    fontFamily: "neue-haas-grotesk-display, sans-serif",
    fontSize: 14,
    paddingBottom: theme.spacing(.5),
  },
  itemText: {
    ...titleTypography,
    '& span': {
      fontSize: 32,
      position: 'relative',
      [theme.breakpoints.down("sm")]: {
        fontSize: 26,
      },
      '&::after': {
        backgroundImage: `url(${arrow})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        bottom: 0,
        content: '""',
        display: 'block',
        height: 14,
        left: '100%',
        margin: 'auto auto auto 12px',
        position: 'absolute',
        top: 2,
        width: 14,
      },
      '& a': {
        'text-decoration-color': '#283224',
      },
    },
  },
  itemLink: {
    textDecoration: 'none',
  },
  divider: {
    margin: '0 8px',
  }
});

function StoryListItem({ classes, className, quote, author, item }) {
  const formattedDate = moment(item.date).format("MMMM Do, YYYY");
  const divider = <span className={classes.divider}>{`\u2022`}</span>
  const description = item.participantDescription ? <span>{divider}{item.participantDescription}</span> : ''
  const state = item.state ? <span>{divider}{item.state.split(", ").sort().join(", ")}</span> : ''

  return (
    <ListItem className={classes.listItem}>
      <span className={classes.itemDetails}>{formattedDate}{description}{state}</span>
      <ListItemText className={classes.itemText}>
        <Link className={classes.itemLink} href={`/oral-history-project/${slugify(item.participantName, {lower: true, strict: true})}`}>{item.participantName}</Link>
      </ListItemText>
    </ListItem>
  );
}

export default withStyles(styles)(StoryListItem);
