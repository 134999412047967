import { useMemo } from "react";
import * as JsSearch from "js-search";
import { useQuery } from "react-query";

const indexedFields = ["date", "keywords", "participantName", "participantDescription", "interviewText", "interviewTextCont"];

export default function StorySearchData(posts) {

  const { data } = useQuery('posts', () => {return posts})

  const search = useMemo(() => {
    if (!data) return null;
  
    data.forEach((item, idx) => {
      item.id = idx;
      return item;
    });
  
    const searchFn = new JsSearch.Search("id");
    indexedFields.forEach((f) => searchFn.addIndex(f));
    searchFn.addDocuments(data);
  
    return searchFn;
  }, [data]);

  return { search };
}
