import React from "react";
import { withStyles } from "@material-ui/core";
import StoryListQuote from "./StoryListQuote";
import StoryListTitle from "./StoryListTitle";
import clsx from "clsx";
import {
  CONTENT_MAXWIDTH_LG,
  CONTENT_MAXWIDTH_XL,
} from "../../gatsby-theme-hypercore/theme";
import scribble from '../../../static/images/scribble.svg'

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(0),
    overflow: 'hidden',
    paddingTop: theme.spacing(9),
    paddingBottom: theme.spacing(12),
    position: 'relative',
    [theme.breakpoints.up("lg")]: {
      marginTop: theme.spacing(8),
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: theme.spacing(9),
    },
    '&::before': {
      backgroundColor: '#F9FCF8',
      content: '""',
      height: '100%',
      marginTop: `-${theme.spacing(9)}`,
      position: 'absolute',
      right: 0,
      width: '100%',
      [theme.breakpoints.up("lg")]: {
        maxWidth: `calc(((100vw - ${CONTENT_MAXWIDTH_LG}px)/2) + ${CONTENT_MAXWIDTH_LG}px - ${theme.spacing(6)})`,
      },
      [theme.breakpoints.up("xl")]: {
        maxWidth: `calc(((100vw - ${CONTENT_MAXWIDTH_LG}px)/2) + ${CONTENT_MAXWIDTH_XL}px - ${theme.spacing(6)})`,
      },
    }
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    position: 'relative',
    width: '100%',
    [theme.breakpoints.only("sm")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),  
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: 'column!important',
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),  
    },
    [theme.breakpoints.only("lg")]: {
      paddingLeft: theme.spacing(12),
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: CONTENT_MAXWIDTH_LG,
    },
    [theme.breakpoints.up("xl")]: {
      maxWidth: CONTENT_MAXWIDTH_XL,
    },
  },
});

function StoryListHero({ classes, className, title, ...props }) {
  return (
    <div className={clsx(classes.root, className)}>
      <div className={clsx(classes.container, className)}>
        <StoryListTitle />
        <StoryListQuote />
      </div>
    </div>
  );
}

export default withStyles(styles)(StoryListHero);