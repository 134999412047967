import React from "react";
import { withStyles } from "@material-ui/core";
import StorySearchContainer from "./filters/StorySearchContainer";
import StoryKeywordContainer from "./filters/StoryKeywordContainer";
import StoryDateContainer from "./filters/StoryDateContainer"
import StoryStateContainer from "./filters/StoryStateContainer";
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'
import { Button } from "@material-ui/core";

const queryClient = new QueryClient()

const styles = (theme) => ({
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(8),
    width: theme.columnSpacing(3),
    [theme.breakpoints.only("sm")]: {
      width: theme.columnSpacing(5),
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(4),
      width: '100%',
    },
  },
  filter: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(2),
    '& span': {
      color: '#555526',
      fontSize: 14,
      [theme.breakpoints.only("sm")]: {
        marginBottom: theme.spacing(1),
      },  
    },
    [theme.breakpoints.only("sm")]: {
      alignItems: 'start',
      flexDirection: 'column',
    },
  },
  clear: {
    borderColor: '#555526',
    lineHeight: 1.4,
    marginLeft: 'auto',
    padding: '0.5rem 1.5rem',
    [theme.breakpoints.only("md")]: {
      marginLeft: 10,
    },
    [theme.breakpoints.only("sm")]: {
      marginLeft: 0,
    },
    '& span': {
      color: '#555526',
      fontSize: 14,
      marginBottom: 0,
    }
  },
});

function StoryListSide({ classes, keywords, states, allPosts, ...props }) {

  const [searchActive, setSearchActive] = React.useState(false);
  const [keywordActive, setKeywordActive] = React.useState(false);
  const [dateActive, setDateActive] = React.useState(false);
  const [statesActive, setStatesActive] = React.useState(false);

  const searchResults = (posts) => {props.searchResults(posts)}
  const resetSearchResults = (reset) => {props.resetSearchResults(reset)}
  const setSearchToActive = () => {setSearchActive(true); setKeywordActive(false); setDateActive(false); setStatesActive(false)}

  const keywordTerms = (terms) => {props.keywordTerms(terms)}
  const keywordReset = (val) => {props.keywordReset(val)}
  const setKeywordToActive = () => {setSearchActive(false); setKeywordActive(true); setDateActive(false); setStatesActive(false)}

  const startDate = (date) => {props.startDate(date)}
  const endDate = (date) => {props.endDate(date)}
  const setDateToActive = () => {setSearchActive(false); setKeywordActive(false); setDateActive(true); setStatesActive(false)}

  const statesChecked = (states) => {props.statesChecked(states)}
  const statesReset = (val) => {props.statesReset(val)}
  const setStatesToActive = () => {setSearchActive(false); setKeywordActive(false); setDateActive(false); setStatesActive(true)}

  const clearFilters = () => {
    if (!searchActive) {
      setKeywordActive(false); setDateActive(false); setStatesActive(false); props.resetSearchResults(true)
    }
  }

  return (
    <side className={classes.sidebar}>

      <QueryClientProvider client={queryClient} contextSharing={true}>
        <StorySearchContainer
          allPosts={allPosts} 
          searchResults={searchResults} // results from search input
          resetSearchResults={resetSearchResults} // reset search results on empty input
          searchActive={searchActive} // reset other filters when search is active
          setSearchToActive={setSearchToActive} // enable/disable search
        />
      </QueryClientProvider>

      <div className={classes.filter}>
        <span>Filter results</span>
        <Button className={classes.clear} onClick={clearFilters}>Clear Filters</Button>
      </div>

      <StoryKeywordContainer
        keywords={keywords} // all keywords
        keywordTerms={keywordTerms} // array of checked keywords
        keywordReset={keywordReset} // reset post results if no checked keywords
        keywordActive={keywordActive} // reset other filters when keyword filter is active
        setKeywordToActive={setKeywordToActive} // enable/disable keyword filter
      />

      <StoryDateContainer 
        startDate={startDate} // start date
        endDate={endDate} // end date
        dateActive={dateActive} // reset other filters when date filter is active
        setDateToActive={setDateToActive} // enable/disable date filter 
      />

      <StoryStateContainer 
        states={states} // all states
        statesChecked={statesChecked} // array of checked states
        statesReset={statesReset} // reset post results if no checked states
        statesActive={statesActive} // reset other filters when state filter is active
        setStatesToActive={setStatesToActive} // enable/disable state filter 
      />

    </side>
  );
}

export default withStyles(styles)(StoryListSide);
