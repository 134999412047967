import React from "react";
import StoryListHero from "./StoryListHero";
import StoryListMain from "./StoryListMain";

const StoryIndex = ({ classes, title, quote, author, data, pageContext, ...props }) => {
  return (
    <>
      <StoryListHero title={title} quote={quote} author={author} />
      <StoryListMain data={data} pageContext={pageContext} />
    </>
  )
};

export default StoryIndex;