export default function StoryStateData(states, posts) {

  var results = []
  posts.map((post) => {
    var statesArr = post.state.split(',')
    statesArr = statesArr.map(s => s.trim().charAt(0).toUpperCase() + s.trim().slice(1));
    states.map((state) => {
      if (statesArr.includes(state)) {
        results.push(post)
      }
    })
  })
  results = [...new Set(results)];
  return results
}
