import React from "react";
import { withStyles, Link } from "@material-ui/core";
import { compactTitleTypography, serifTypography } from "../../gatsby-theme-hypercore/theme";
import scribbleSmall from '../../../static/images/scribble-2.svg'

const styles = (theme) => ({
  titleSection: {
    width: '100%',
    [theme.breakpoints.up("md")]: {
      maxWidth: '40%',
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: '2rem',
    },
  },
  blurb: {
    ...serifTypography,
    fontSize: '1rem',
    lineHeight: 1.5,
    [theme.breakpoints.up("lg")]: {
      fontSize: '.9rem',
    },
  },
  title: {
    ...compactTitleTypography,
    color: '#283224',
    display: 'inline-flex',
    letterSpacing: 12 / 25,
    lineHeight: 1.2,
    marginRight: 'auto',
    marginBottom: theme.spacing(2),
    marginTop: 0,
    position: 'relative',
    [theme.breakpoints.up("lg")]: {
      fontSize: '3rem',
    },
    [theme.breakpoints.only("md")]: {
      fontSize: '3.5rem',
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: '2.75rem',
    },
    '&::before': {
      backgroundImage: `url(${scribbleSmall})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      bottom: '-45%',
      content: '""',
      display: 'flex',
      height: 80,
      right: '7%',
      position: 'absolute',
      width: 80,
      [theme.breakpoints.down("sm")]: {
        bottom: -30,
        right: -20,
      },
    },
    [theme.breakpoints.up("sm")]: {
      whiteSpace: 'nowrap',
    },
  },
});

function StoryListTitle({ classes, className, quote, author }) {  
  return (
    <div className={classes.titleSection}>
      <h2 className={classes.title}>Oral History Project</h2>
      <div className={classes.blurb}>The Behind Bars Data Project conducts oral history interviews with attorneys and advocates working on behalf of incarcerated clients during the COVID pandemic. <Link href="http://uclacovidbehindbars.org/oral-history-blog">See this post</Link> for more information about this project and contact <Link href="mailto:behindbars@law.ucla.edu">behindbars@law.ucla.edu</Link> with any questions.</div>
    </div>
  );
}

export default withStyles(styles)(StoryListTitle);
