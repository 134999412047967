import React from "react";
import { useEffect } from "react";
import { withStyles, useTheme, useMediaQuery } from "@material-ui/core";
import DownArrow from '../../../../static/images/down-arrow.svg'

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    '& label': {
      cursor: 'pointer',
    },
  },
  title: {
    color: '#555526',
    cursor: 'pointer',
    display: 'flex',
    fontSize: 14,
    marginBottom: theme.spacing(1),
    '& span': {
      position: 'relative',
    },
    '& ::after': {
      backgroundImage: `url(${DownArrow})`,
      bottom: 0,
      content: '""',
      display: 'block',
      height: 5,
      left: '100%',
      margin: 'auto',
      marginLeft: 5,
      position: 'absolute',
      top: 0,
      width: 9,
    },
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
  },
  stateLabel: {
    color: '#555526',
    fontSize: 14,
    lineHeight: 1.2,
    marginBottom: 5,
    paddingLeft: 15,
    position: 'relative',
  },
  stateInput: {
    cursor: 'pointer',
    height: 0,
    opacity: 0,
    position: 'absolute',
    width: 0,
    '& input:checked': {
      border: '1px solid red',
    },
    '&:focus + span': {
      outline: '2px solid #555526',
    },
  },
  checkmark: {
    border: '1px solid #555526',
    bottom: 0,
    height: 10,
    left: 0,
    margin: 'auto',
    position: 'absolute',
    top: 0,
    width: 10,
    '&.checked': {
      backgroundColor: '#555526',
    },
  },
});

const StoryStateContainer = ({ classes, states, statesActive, ...props }) => {

  const [checkedStates, setCheckedStates] = React.useState(null);
  const checkedArrInit = Array(states.length).fill(null);
  const checkedArrFalse = Array(states.length).fill(false);
  const [checkedArr, setCheckedArr] = React.useState(checkedArrInit);
  const [showStates, setShowStates] = React.useState(true)
  const [isMobile, setIsMobile] = React.useState(null)

  useEffect(() => {
    // reset list results if no keywords are checked
    if (checkedStates) {
      (checkedStates.length) === 0 ? props.statesReset(true) : props.statesChecked(checkedStates)
    }
  }, [checkedStates]);

  useEffect(() => {
    if (!statesActive) {
      props.statesReset(true)
      setCheckedArr(checkedArrFalse)
      setCheckedStates(null)
    }
  }, [statesActive]);

  const checkboxHandler = (e, reset) => {
    const val = e.target.value

    if (e.target.checked || e.which == 13) { 
      // update checkedArr
      const checkedArrAdd = checkedArr
      checkedArrAdd[states.indexOf(val)] = true
      setCheckedArr(checkedArrAdd)

      // whenever a value is checked, reset other filters
      props.setStatesToActive(true)

      if (!checkedStates) {
        setCheckedStates([])
      }
      setCheckedStates(checkedStates => [...checkedStates, val])
    } else if (!e.target.checked) {
      // update checkedArr
      const checkedArrSub = checkedArr
      checkedArrSub[states.indexOf(val)] = false
      setCheckedArr(checkedArrSub)

      setCheckedStates(checkedStates => checkedStates.filter(function(state) { 
        return state !== val
      }))
    }
  };

  const toggleStates = () => {
    setShowStates(!showStates)
  }

  const theme = useTheme();
  if (useMediaQuery(theme.breakpoints.down("xs"))) {
    if (!isMobile) {
      setIsMobile(true)
      setShowStates(false)
    }
  } else {
    if (isMobile) {
      setIsMobile(false)
      setShowStates(true)
    }
  }

  return (
    <div className={classes.container}>

      <div className={classes.title} onClick={toggleStates}><span>State</span></div>

      {showStates &&
        <div className={classes.list}>
          {states.map(function(state, i) {
            const checked = checkedArr[i]
            var classVal = classes.checkmark
            if (checked) {
              classVal = classes.checkmark + ' checked'
            }
            return <label className={classes.stateLabel}><input type='checkbox' className={classes.stateInput} onChange={checkboxHandler} onKeyPress={checkboxHandler} value={state} checked={checked} />{state}<span className={classVal}></span></label>
          })}
        </div>
      }
    </div>
  );
}

export default withStyles(styles)(StoryStateContainer);