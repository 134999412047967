import React from "react";
import { graphql } from "gatsby";
import Layout from "gatsby-theme-hypersite/src/layout";
import StoryIndex from "../components/stories/StoryIndex";
import { withStyles } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    '& main': {
      backgroundColor: '#FFFFFF',
      '& :focus': {
        outline: '2px solid #555526',
        borderRadius: 4,
      },
    },
  }
});

const StoryListTemplate = ({ data, pageContext, classes, ...props }) => {
  return (
    <div className={classes.root}>
      <Layout>
        <StoryIndex 
          data={data.allStories.nodes}
          pageContext={pageContext} 
        />
      </Layout>
    </div>
  );
};
export default withStyles(styles)(StoryListTemplate);

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allStories(
      limit: $limit
      skip: $skip
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        date
        participantDescription
        participantName
        state
      }
    }
  }
`