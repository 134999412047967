import * as React from 'react';
import TextField from '@mui/material/TextField';
import { withStyles } from "@material-ui/core";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { sansSerifyTypography } from "../../../gatsby-theme-hypercore/theme";

const styles = (theme) => ({
  datePicker: {
    marginLeft: `${theme.spacing(1)}!important`,
    marginRight: `${theme.spacing(1)}!important`,
    WebkitBoxShadow: "0 0 0 1000px white inset",
    '& input': {
      ...sansSerifyTypography,
      backgroundColor: 'transparent!important',
      color: '#555526',
      fontSize: 14,
      maxWidth: 94,
      padding: 0,
      paddingBottom: 6,
      paddingTop: 6,
      textAlign: 'center',
      textTransform: 'uppercase',
      '&::placeholder, &::-webkit-input-placeholder': {
        color: '#555526',
        opacity: 1,
      },
    },
    '& .MuiInputBase-root': {
      padding: 0,
    },
    '& .MuiInputAdornment-root': {
      height: '100%',
      margin: 0,
      position: 'absolute',
      width: '100%',
      '& button': {
        height: '100%',
        width: '100%',
        '&:hover': {
          borderRadius: 0,
        },  
      },
      '& svg': {
        display: 'none',
      },
    },
    '& fieldset': {
      border: 'none',
      borderBottom: '1px solid #555526!important',
      borderRadius: 0,
    },
    '& button': {
      padding: 0,
    },
  },
});

const popperSx = {
  "& .MuiPaper-root": {
    backgroundColor: "white",
  },
  "& .MuiButtonBase-root": {
    fontFamily: "neue-haas-grotesk-display, sans-serif",
  },
  "& .MuiTypography-root": {
    fontFamily: "neue-haas-grotesk-display, sans-serif",
  },
  "& .MuiPickersCalendarHeader-label": {
    fontFamily: "neue-haas-grotesk-display, sans-serif",
  },
};

function BasicDatePicker({classes, resetDate, dateActive, ...props }) {

  const [date, setDate] = React.useState(null);

  React.useEffect(() => {
    props.dateVal(date)
  }, [date]);

  const changeHandler = (val) => {
    setDate(val)
    props.setDateToActive(true)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        className={classes.datePicker}
        onChange={changeHandler}
        renderInput={(params) => <TextField {...params} inputProps={{...params.inputProps, placeholder: "MM/DD/YYYY" }} />}
        value={dateActive ? date : null}
        views={['day']}
        showDaysOutsideCurrentMonth={true}
        PopperProps={{
          sx: popperSx
        }}
      />
    </LocalizationProvider>
  );
}

export default withStyles(styles)(BasicDatePicker);
