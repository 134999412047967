import React from "react";
import { useEffect } from "react";
import StoryListSide from './StoryListSide';
import StoryList from "./StoryList";
import StoryKeywordData from "./filters/StoryKeywordData";
import StoryDateData from "./filters/StoryDateData";
import StoryStateData from "./filters/StoryStateData";
import { withStyles } from "@material-ui/core";
import {
  CONTENT_MAXWIDTH_LG,
  CONTENT_MAXWIDTH_XL,
} from "../../gatsby-theme-hypercore/theme";

const styles = (theme) => ({
  listWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(8),
    [theme.breakpoints.down("xs")]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.only("sm")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),  
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),  
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: CONTENT_MAXWIDTH_LG,
    },
    [theme.breakpoints.up("xl")]: {
      maxWidth: CONTENT_MAXWIDTH_XL,
    },
  },
});

const StoryListMain = ( {classes, data, pageContext, ...props} ) => {
  const totalPagePosts = data.length > 1 ? 'transcripts' : 'transcript'
  const { total } = pageContext

  const initialPosts = data
  const allPosts = pageContext.allPosts
  const [posts, setPosts] = React.useState(initialPosts);
  const [totalResults, setTotalResults] = React.useState(total);
  const [pagination, setPagination] = React.useState(true);
  const [dateRangeFilter, setDateRangeFilter] = React.useState([null, null])

  useEffect(() => {
    if (dateRangeFilter[0] && dateRangeFilter[1]) {
      const rangePosts = StoryDateData(dateRangeFilter[0], dateRangeFilter[1], allPosts)
      const results = rangePosts.results
      if (rangePosts.results) {
        setPagination(false)
        setPosts(results)
        setTotalResults(results.length)  
      }
    } else if (dateRangeFilter[0] || dateRangeFilter[1]) {
      setPagination(true)
      setPosts(initialPosts)
      setTotalResults(total)
    }
  }, [dateRangeFilter]);

  const searchResults = (posts) => {
    setPagination(false)
    setPosts(posts)
    setTotalResults(posts.length)
  }

  const resetSearchResults = (reset) => {
    if (reset) {
      setPagination(true)
      setPosts(initialPosts)
      setTotalResults(total)
    }
  }

  const keywordTerms = (terms) => {
    const posts = StoryKeywordData(terms, allPosts)
    setPosts(posts)
    setPagination(false)
    setTotalResults(posts.length)
  }

  const keywordReset = (val) => {
    if (val) {
      setPagination(true)
      setPosts(initialPosts)
      setTotalResults(total)
    }
  }

  const startDate = (date) => {
    if (date) {
      date = new Date(date)
      dateRangeFilter[1] ? setDateRangeFilter([date, dateRangeFilter[1]]) : setDateRangeFilter([date, null])
    }
  }

  const endDate = (date) => {
    if (date) {
      date = new Date(date)
      if (dateRangeFilter[0]) {
        setDateRangeFilter([dateRangeFilter[0], date])
        StoryDateData(dateRangeFilter[0], date, allPosts)
      } else {
        setDateRangeFilter([null, date])
      }
    }
  }

  const statesReset = (val) => {
    if (val) {
      setPagination(true)
      setPosts(initialPosts)
      setTotalResults(total)
    }
  }

  const statesChecked = (states) => {
    const posts = StoryStateData(states, allPosts)
    setPosts(posts)
    setPagination(false)
    setTotalResults(posts.length)
  }

  const keywords = () => {
    var kws = []
    pageContext.allPosts.forEach(item => {
      var itemKws = item.keywords.split(',')
      itemKws.forEach(kw => {
        kw = kw.trim().charAt(0).toUpperCase() + kw.trim().slice(1)
        kws.push(kw)
      });
    });
    kws = kws.sort((a, b) => {
      return a.localeCompare(b, undefined, {sensitivity: 'base'});
    });
    kws = [...new Set(kws.sort())]

    return (kws)
  }

  const states = () => {
    var allStates = []
    pageContext.allPosts.forEach(item => {
      var itemStates = item.state.split(',')
      itemStates.forEach(state => {
        allStates.push(state.trim())
      });
    });
    allStates = allStates.sort((a, b) => {
      return a.localeCompare(b, undefined, {sensitivity: 'base'});
    });
    allStates = [...new Set(allStates.sort())]

    return (allStates)
  }

  return (
    <div className={classes.listWrapper}>
      <StoryListSide 
        allPosts={allPosts}
        searchResults={searchResults}
        resetSearchResults={resetSearchResults}
        keywords={keywords()}
        keywordTerms={keywordTerms}
        keywordReset={keywordReset}
        startDate={startDate}
        endDate={endDate}
        states={states()}
        statesReset={statesReset}
        statesChecked={statesChecked}
      />

      <StoryList
        totalResults={totalResults}
        totalPagePosts={totalPagePosts}
        pageContext={pageContext}
        pagination={pagination}
        posts={posts}
      />
    </div>
  )
};

export default withStyles(styles)(StoryListMain);