import React from "react";
import { withStyles } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import StorySearchData from "./StorySearchData";
import SearchIcon from '../../../../static/images/search-icon.svg'

const styles = (theme) => ({
  search: {
    marginBottom: theme.spacing(4),
    '& input': {
      color: '#555526',
      fontSize: 16,
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
      },
    },
    '& ::before': {
      backgroundImage: `url(${SearchIcon})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      border: 'none!important',
      content: '""',
      display: 'flex',
      height: 14,
      marginRight: 12,
      marginTop: 2,
      position: 'relative',
      width: 14,
      [theme.breakpoints.down("sm")]: {
        marginRight: 8,
      },
    },
    '& ::after': {
      borderBottom: '1px solid #555526',
      transform: 'scaleX(1)',
    },
  },
});

const MIN_SEARCH_CHARS = 3;

function StorySearchContainer({ classes, ...props }) {

  const [searchTerm, setSearchTerm] = React.useState(null);
  const { search } = StorySearchData(props.allPosts);

  const searchValue = () => {
    if (props.searchActive) {
      return searchTerm || ""
    } else {
      return ''
    }
  }

  const searchHandler = (e, reset) => {
    const term = reset ? "" : e.target.value;
    setSearchTerm(term);
    props.setSearchToActive(true)

    if (e.target.value === '') {
      props.resetSearchResults(true);
    }

    if (!term || term.length < MIN_SEARCH_CHARS) {
      props.resetSearchResults(true);
      return;
    }

    if (search) {
      const items = search.search(term);
      props.searchResults(items);
    }
  };

  return (
    <TextField
      className={classes.search}
      onChange={searchHandler}
      placeholder="Search by keyword or name"
      value={searchValue()}
    />
  );
};

export default withStyles(styles)(StorySearchContainer);
