export default function StoryKeywordData(keywords, posts) {

  var results = []
  posts.map((post) => {
    var kwArr = post.keywords.split(',')
    kwArr = kwArr.map(s => s.trim().charAt(0).toUpperCase() + s.trim().slice(1));
    keywords.map((kw) => {
      if (kwArr.includes(kw)) {
        results.push(post)
      }
    })
  })
  results = [...new Set(results)];
  return results
}
