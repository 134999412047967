import React from "react";
import { Link } from "gatsby";
import { withStyles, List } from "@material-ui/core";
import chevron from '../../../static/images/chevron.svg'

const styles = (theme) => ({
  pagination: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(6),
    '& a': {
      textDecoration: 'none',
    },
  },
  pageNumbers: {
    margin: 'auto',
    '& a': {
      color: '#283224',
      fontSize: 14,
      padding: '0 8px',
      textDecoration: 'none',
      '&[aria-current]': {
        fontWeight: 'bold',
        '& > span': {
          borderBottom: '1px solid #283224',
          paddingBottom: 2,
        }
      },
    },
  },
  prev: {
    backgroundImage: `url(${chevron})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    height: 25,
    width: 12,
  },
  next: {
    backgroundImage: `url(${chevron})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    height: 25,
    transform: 'rotate(180deg)',
    width: 12,
  },
});


function StoryListPagination( {pageContext, classes, ...props} ) {
  const { currentPage, numPages } = pageContext
  const root = 'oral-history-project/'
  const prevPage = currentPage - 1 <= 1 ? '/' + root : '/' + root + (currentPage - 1).toString()
  const nextPage = currentPage <= numPages - 1 ? '/' + root + (currentPage + 1).toString() : '/' + root + currentPage.toString()

  return (
    <nav className={classes.pagination}>
      <Link to={prevPage} rel="prev" className={classes.prev} />
 
      <div className={classes.pageNumbers}>
        {Array.from({ length: numPages }, (_, i) => (
          <Link key={`pagination-number${i + 1}`} to={`/${i === 0 ? root : root + (i + 1)}`}>
            <span>{i + 1}</span>
          </Link>
        ))}
      </div>
    
      <Link to={nextPage} rel="next" className={classes.next} />
    </nav>
  )
}

export default withStyles(styles)(StoryListPagination);