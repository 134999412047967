import React from "react";
import StoryListItem from "./StoryListItem";
import StoryListPagination from "./StoryListPagination";
import { withStyles, List } from "@material-ui/core";

const styles = (theme) => ({
  list: {
    marginBottom: theme.spacing(10),
    width: '100%',
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.columnSpacing(1),
    },
    [theme.breakpoints.only("sm")]: {
      paddingLeft: theme.spacing(4),
    },
  },
  total: {
    color: '#555526',
    display: 'block',
    fontSize: 14,
    marginBottom: theme.spacing(4),
  }
});

function StoryList({ classes, totalResults, totalPagePosts, pageContext, pagination, posts, ...props }) {
  return (
    <List className={classes.list}>
      <span className={classes.total}>{totalResults} {totalPagePosts}</span>

      {posts.map((item) => (
        <StoryListItem item={item} />
      ))}

      {pagination > 0 &&
        <StoryListPagination
          pageContext = {pageContext}
        />
      }
    </List>
  );
}

export default withStyles(styles)(StoryList);
