import React from "react";
import { useEffect } from "react";
import { withStyles, useTheme, useMediaQuery } from "@material-ui/core";
import DownArrow from '../../../../static/images/down-arrow.svg'

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    '& label': {
      cursor: 'pointer',
    },
  },
  title: {
    color: '#555526',
    display: 'flex',
    fontSize: 14,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      cursor: 'pointer',
    },
    '& span': {
      cursor: 'pointer',
      position: 'relative',
    },
    '& ::after': {
      backgroundImage: `url(${DownArrow})`,
      bottom: 0,
      content: '""',
      display: 'block',
      height: 5,
      left: '100%',
      margin: 'auto',
      marginLeft: 5,
      position: 'absolute',
      top: 0,
      width: 9,
    },
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
    // [theme.breakpoints.down("sm")]: {
    //   height: 0,
    //   overflow: 'hidden',
    // },
  },
  kwLabel: {
    color: '#555526',
    fontSize: 14,
    lineHeight: 1.2,
    marginBottom: 5,
    paddingLeft: 15,
    position: 'relative',
  },
  kwInput: {
    cursor: 'pointer',
    height: 0,
    opacity: 0,
    position: 'absolute',
    width: 0,
    '& input:checked': {
      border: '1px solid red',
    },
    '&:focus + span': {
      outline: '2px solid #555526',
    },
  },
  checkmark: {
    border: '1px solid #555526',
    bottom: 0,
    height: 10,
    left: 0,
    margin: 'auto',
    position: 'absolute',
    top: 0,
    width: 10,
    '&.checked': {
      backgroundColor: '#555526',
    },
  },
});

const KeywordFilter = ({ classes, keywords, keywordActive, ...props }) => {

  const [keywordTerms, setKeywordTerms] = React.useState(null);
  const checkedArrInit = Array(keywords.length).fill(null);
  const checkedArrFalse = Array(keywords.length).fill(false);
  const [checkedArr, setCheckedArr] = React.useState(checkedArrInit);
  const [showKeywords, setShowKeywords] = React.useState(true)
  const [isMobile, setIsMobile] = React.useState(null)

  useEffect(() => {
    // reset list results if no keywords are checked
    if (keywordTerms) {
      (keywordTerms.length) === 0 ? props.keywordReset(true) : props.keywordTerms(keywordTerms)
    }
  }, [keywordTerms]);

  useEffect(() => {
    if (!keywordActive) {
      props.keywordReset(true)
      setCheckedArr(checkedArrFalse)
      setKeywordTerms(null)
    }
  }, [keywordActive]);

  const checkboxHandler = (e, reset) => {
    const val = e.target.value

    if (e.target.checked || e.which == 13) { 
      // update checkedArr
      const checkedArrAdd = checkedArr
      checkedArrAdd[keywords.indexOf(val)] = true
      setCheckedArr(checkedArrAdd)

      // whenever a value is checked, reset other filters
      props.setKeywordToActive(true)

      if (!keywordTerms) {
        setKeywordTerms([])
      }
      setKeywordTerms(keywordTerms => [...keywordTerms, val])
    } else if (!e.target.checked) {
      // update checkedArr
      const checkedArrSub = checkedArr
      checkedArrSub[keywords.indexOf(val)] = false
      setCheckedArr(checkedArrSub)

      setKeywordTerms(keywordTerms => keywordTerms.filter(function(kw) { 
        return kw !== val
      }))
    }
  };

  const toggleKeywords = () => {
    setShowKeywords(!showKeywords)
  }

  const theme = useTheme();
  if (useMediaQuery(theme.breakpoints.down("xs"))) {
    if (!isMobile) {
      setIsMobile(true)
      setShowKeywords(false)
    }
  } else {
    if (isMobile) {
      setIsMobile(false)
      setShowKeywords(true)
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.title} onClick={toggleKeywords}><span>Summary keywords</span></div>

      {showKeywords &&
        <div className={classes.list}>
          {keywords.map(function(kw, i) {
            const checked = checkedArr[i]
            var classVal = classes.checkmark
            if (checked) {
              classVal = classes.checkmark + ' checked'
            }
            return <label className={classes.kwLabel}><input type='checkbox' className={classes.kwInput} onChange={checkboxHandler} onKeyPress={checkboxHandler} value={kw} checked={checked} />{kw}<span className={classVal}></span></label>
          })}
        </div>
      }
    </div>
  );
}

export default withStyles(styles)(KeywordFilter);