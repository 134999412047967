import * as React from 'react';
import { useEffect } from "react";
import { withStyles, useTheme, useMediaQuery } from "@material-ui/core";
import BasicDatePicker from './BasicDatePicker';
import DownArrow from '../../../../static/images/down-arrow.svg'

const styles = (theme) => ({
  title: {
    color: '#555526',
    cursor: 'pointer',
    fontSize: 14,
    marginBottom: theme.spacing(1),
    '& span': {
      position: 'relative',
    },
    '& ::after': {
      backgroundImage: `url(${DownArrow})`,
      bottom: 0,
      content: '""',
      display: 'block',
      height: 5,
      left: '100%',
      margin: 'auto',
      marginLeft: 5,
      position: 'absolute',
      top: 0,
      width: 9,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(2),
    marginLeft: `-${theme.spacing(1)}`,
    marginRight: `-${theme.spacing(1)}`,
  },
  dash: {
    color: '#555526',
    display: 'flex',
    alignSelf: 'center',
    marginBottom: 2,
  },
});

function StoryDateContainer({classes, dateActive, ...props}) {

  const [resetDate, setResetDate] = React.useState(null);
  const [showDate, setShowDate] = React.useState(true)
  const [isMobile, setIsMobile] = React.useState(null)

  useEffect(() => {
    setResetDate(dateActive)
  }, [dateActive]);

  const startDate = (date) => {
    props.startDate(date)
  }

  const endDate = (date) => {
    props.endDate(date)
  }
  
  const setDateToActive = (val) => {
    props.setDateToActive(val)
  }

  const toggleDate = () => {
    setShowDate(!showDate)
  }

  const theme = useTheme();
  if (useMediaQuery(theme.breakpoints.down("xs"))) {
    if (!isMobile) {
      setIsMobile(true)
      setShowDate(false)
    }
  } else {
    if (isMobile) {
      setIsMobile(false)
      setShowDate(true)
    }
  }

  return (
    <>
      <div className={classes.title} onClick={toggleDate}><span>Date</span></div>

      {showDate &&
        <div className={classes.container}>
          <BasicDatePicker dateVal={startDate} resetDate={resetDate} dateActive={dateActive} setDateToActive={setDateToActive} />
          <span className={classes.dash}>{`\u0020\u002D\u0020`}</span>
          <BasicDatePicker dateVal={endDate} resetDate={resetDate} dateActive={dateActive} setDateToActive={setDateToActive} />
        </div>
      }
    </>
  );
}

export default withStyles(styles)(StoryDateContainer);
