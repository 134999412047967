import moment from "moment";

export default function StoryDateData(startDate, endDate, posts) {
  const start = moment(startDate)
  const end = moment(endDate)
  const results = []

  if (end.diff(start) >= 0) {
    posts.map((post) => {
      const postDate = moment(post.date)
      if ((end.diff(postDate) >= 0) && (postDate.diff(start) >= 0)) {
        results.push(post)
      }
    })
  }

  return { results }
}
